export default [
  {
    name: 'email-campaign',
    path: '/email/campaign',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Campanhas E-mails',
      icon: 'dvr',
      label: 'Campanhas E-mails',
      roles: ['Admin']
    }
  },
  {
    name: 'email-campaign-details',
    path: '/email/campaign/:id',
    component: () => import(/* webpackChunkName: "event" */ './Detail'),
    meta: {
      showNavbar: false,
      title: 'Campanhas E-mails - Detalhes da campanha',
      icon: 'dvr',
      label: 'Campanhas E-mails - Detalhes da campanha',
      roles: ['Admin']
    }
  }
]

// 'wa-campaign-details

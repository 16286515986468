import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_EMAILTEMPLATES, res.data)
  })
)

export const show = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_EMAILTEMPLATE, res.data.data)
  })
}

export const create = async ({ commit }, obj) => {
  await services.newTemplate(obj).then(res => {
    commit(types.SET_EMAILTEMPLATE, res.data.data)
    return res
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveReward(obj).then(res => {
    commit(types.SET_EMAILTEMPLATE, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }) => {
  await services.changeStatus().then(res => {
    return res
  })
}

export const destroyHeader = async ({ commit }, id) => {
  await services.removeHeader(id).then(res => {
    return res
  })
}

export const orderList = async ({ commit }, obj) => {
  await services.orderList(obj).then(res => {
    commit(types.SET_EMAILTEMPLATES, res.data)
    return res.data
  })
}

export const deleteReward = async ({ commit }, obj) => {
  const data = await services.deleteReward(obj).then(res => {
    return res
  })
  return data
}

export default {
  'ECAMPAIGN' (state) {
    return state.campaign
  },
  'ECAMPAIGNS' (state) {
    return state.campaigns
  },
  'ECONTACTS' (state) {
    return state.contacts
  }
}

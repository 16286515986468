import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
  }
})

export default {
  [types.SET_ECAMPAIGN] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.campaign = payload
  },
  [types.SET_ECAMPAIGNS] (state, payload) {
    state.campaigns = payload.data
    state.pagination = payload.meta.pagination
  },
  [types.SET_ECONTACTS] (state, payload) {
    state.contacts = payload.data
    state.paginationC = payload.meta.pagination
  }
}

import http from '@/http'
const URL = '/admin/wa/campaigns'

export const listContacts = async (obj) => {
  console.log(obj)
  const list = (await http.get(URL + `/${obj.id}/contacts`, { params: obj.params }))
  return list
}

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const saveCampaign = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const exportCampaignMessages = async (params) => {
  const list = (await http.get(URL + `/${params.id}/export`, { params: params, responseType: 'blob' })).data
  return list
}
export const deleteCampaign = async (id) => http.delete(URL + `/${id}`)
export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export default {
  listContacts,
  list,
  show,
  saveCampaign,
  exportCampaignMessages,
  deleteCampaign,
  changeStatus
}
